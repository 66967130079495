import React from "react";

export const MembershipDurationOptions = () => {
  return (
    <>
      <option value={0} disabled>
        Duration
      </option>
      <option value={1}>1 Month</option>
      <option value={3}>3 Months</option>
      <option value={6}>6 Months</option>
      <option value={12}>12 Months</option>
      {/* <option value={1200}>1200 Months</option> */}
    </>
  );
};
