import React from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Form,
  FormSelect,
} from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import {
  Control,
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
  UseFormSetError,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { useMutation, useQuery } from "react-query";

import {
  IReturnGetCustomers,
  IReturnGetMembeship,
  MembershipInfoTypesType,
  carwashApi_createCar,
  carwashApi_createCustomer,
  carwashApi_createMembership,
  carwashApi_getCars,
  carwashApi_getCustomers,
  carwashApi_pushCarCustomer,
  carwashApi_pushCarMembership,
  carwashApi_updateCustomer,
  carwashApi_updateMembership,
} from "../../carwashApi";
import { DateTime } from "luxon";
import { useWatchAndQuery } from "../../hooks/useWatchAndQuery";
import { If } from "../If.component";
import {
  flatMap,
  flatMapDeep,
  flattenDeep,
  get,
  isString,
  mapValues,
  values,
} from "lodash";
import {
  flattenErrorFieldReactHookForm,
  thousandCommas,
} from "../../utilities";
import ReactDatePicker from "react-datepicker";
import { MembershipDurationOptions } from "./MembershipDurationOptions.component";
import ArrayNomorKartusFields from "./Component/ArrayNomorKartusFields.component";
import ArrayCarFields from "./Component/ArrayCarFields.component";
import { useCheckCarMembershipCuciUnlimited } from "./Hooks/useCheckCarMembership.hooks";
import { useSecretFeaturesFlag } from "../../hooks/useSecretFeatures";

export interface CreateMemberShipFormType {
  customer_name: string;
  customer_phone_number: string;
  duration: string;
  cars: {
    plate_number: string;
    car_brand: string;
    car_color: string;
  }[];
  membership_nomor_kartus?: { nomor: string }[];
  price: string;
  issue_date?: Date;
}

export type SubmissionMode = "create" | "update";

function checkIsToUpdate(
  submissionMode: SubmissionMode | undefined,
  foundMembership:
    | Exclude<IReturnGetCustomers["memberships"], undefined>[number]
    | undefined
): foundMembership is Exclude<
  IReturnGetCustomers["memberships"],
  undefined
>[number] {
  const updateFlag = submissionMode ? submissionMode === "update" : true;
  return !!(foundMembership && updateFlag);
  // return !!foundMembership;
}

const useHandleSubmitOnValid = () => {
  const carwashApiCreateOrGetCar = useMutation({
    mutationFn: async (p: {
      plate_number: string;
      car_brand?: string;
      car_color: string;
    }) => {
      const car = await carwashApi_createCar(p).then((r) => r.data);
      return car;
    },
  });

  const carwashApiCreateMembership = useMutation({
    mutationFn: carwashApi_createMembership,
  });

  const carwashApiUpdateMembership = useMutation({
    mutationFn: carwashApi_updateMembership,
  });

  const carwashApiCreateCustomer = useMutation({
    mutationFn: carwashApi_createCustomer,
  });

  const carwashApiUpdateCustomer = useMutation({
    mutationFn: carwashApi_updateCustomer,
  });

  const carwashApiCarPushCustomer = useMutation({
    mutationFn: carwashApi_pushCarCustomer,
  });

  const carwashApiCarPushMembership = useMutation({
    mutationFn: carwashApi_pushCarMembership,
  });

  const carMembershipCheckCb = useCheckCarMembershipCuciUnlimited();

  return React.useCallback(
    async (p: {
      data: CreateMemberShipFormType;
      foundCustomer: IReturnGetCustomers | undefined;
      foundMembership:
        | Exclude<IReturnGetCustomers["memberships"], undefined>[number]
        | undefined;
      setError: UseFormSetError<CreateMemberShipFormType>;
      extendExpireDate: boolean;
      submissionMode?: SubmissionMode;
    }) => {
      console.log(p.data);
      const foundCustomerData = p.foundCustomer;
      const foundMembership = p.foundMembership;

      const customer = foundCustomerData
        ? await carwashApiUpdateCustomer
            .mutateAsync({
              customer_id: foundCustomerData.customer_id,
              customer_name: p.data.customer_name,
              customer_phone_number: p.data.customer_phone_number,
            })
            .then((r) => r.data)
        : await carwashApiCreateCustomer
            .mutateAsync({
              customer_name: p.data.customer_name,
              customer_phone_number: p.data.customer_phone_number,
              // car_plate_numbers: [p.data.plate_number],
            })
            .then((r) => {
              return r.data;
            });

      const carMembershipCheck = await carMembershipCheckCb({
        cars: p.data.cars,
        target_customer_id: customer.customer_id,
      });
      const nokCar = carMembershipCheck.filter((c) => c.status === "NOK");
      if (nokCar.length > 0) {
        nokCar.forEach((c) => {
          const index = p.data.cars.findIndex(
            (car) => car.plate_number === c.plate_number
          );

          p.setError(`cars.${index}.plate_number`, {
            message: c.message,
          });
        });
        return Promise.reject();
      }

      const issue_date = p.data.issue_date || new Date();

      const expire_date = (() => {
        if (!p.extendExpireDate && p.submissionMode === "update") {
          return foundMembership?.expire_date || new Date();
        }

        if (
          foundMembership &&
          new Date(foundMembership.expire_date) > issue_date
        ) {
          return DateTime.fromJSDate(new Date(foundMembership.expire_date))
            .plus({
              month: parseInt(p.data.duration),
            })
            .set({
              hour: 23,
              minute: 59,
              second: 50,
            })
            .toJSDate();
        } else {
          return DateTime.fromJSDate(issue_date)
            .plus({ month: parseInt(p.data.duration) })
            .minus({ day: 1 })
            .set({ hour: 23, minute: 59, second: 50 })
            .toJSDate();
        }
      })();

      const membership = checkIsToUpdate(p.submissionMode, foundMembership)
        ? await carwashApiUpdateMembership
            .mutateAsync({
              membership_id: foundMembership.membership_id,
              customer_id: customer.customer_id,
              expire_date,
              issue_date,
              plate_numbers: p.data.cars.map((c) => c.plate_number),
              membership_nomor_kartus:
                p.data.membership_nomor_kartus
                  ?.map((n) => n.nomor)
                  .filter((n) => !!n) || [],
              duration: parseInt(p.data.duration),
              price: parseInt(p.data.price.replaceAll(",", "")),
            })
            .then((r) => r.data)
        : await carwashApiCreateMembership
            .mutateAsync({
              customer_id: customer.customer_id,
              expire_date,
              issue_date,
              member_type: "cuci-unlimited",
              plate_numbers: p.data.cars.map((c) => c.plate_number),
              membership_nomor_kartus:
                p.data.membership_nomor_kartus
                  ?.map((n) => n.nomor)
                  .filter((n) => !!n) || [],
              duration: parseInt(p.data.duration),
              price: parseInt(p.data.price.replaceAll(",", "")),
            })
            .then((r) => r.data);

      // await Promise.all(
      //   p.data.cars.map(async (car) => {
      //     await carwashApiCarPushCustomer.mutateAsync({
      //       customer_id: customer.customer_id,
      //       plate_number: car.plate_number,
      //     });

      //     await carwashApiCarPushMembership.mutateAsync({
      //       membership_id: membership.membership_id,
      //       plate_number: car.plate_number,
      //     });
      //   })
      // );
    },
    [
      carwashApiCreateOrGetCar,
      carwashApiCreateMembership,
      carwashApiUpdateMembership,
      carwashApiCreateCustomer,
      carwashApiCarPushCustomer,
      carwashApiCarPushMembership,
    ]
  );
};

export const MembershipForm = (p: {
  onCreateSucceed: () => void;
  onUpdateSucceed: () => void;
  membershipData?: IReturnGetMembeship;
  submissionMode?: SubmissionMode;
}) => {
  const createMembershipForm = useForm<CreateMemberShipFormType>({
    values: {
      customer_name: p.membershipData?.customer?.customer_name || "",
      customer_phone_number:
        p.membershipData?.customer?.customer_phone_number || "",
      duration: (p.membershipData?.duration || 0).toString(),
      cars: p.membershipData?.cars?.map((c) => ({
        car_brand: c.car_brand || "",
        car_color: c.car_color || "",
        plate_number: c.plate_number || "",
      })) || [{ car_brand: "", car_color: "", plate_number: "" }],
      membership_nomor_kartus:
        p.membershipData?.membership_info_kartus &&
        p.membershipData.membership_info_kartus.length > 0
          ? p.membershipData?.membership_info_kartus?.map((n) => ({
              nomor: n.nomor_kartu,
            }))
          : [{ nomor: "" }],
      price: thousandCommas((p.membershipData?.price || 0).toString()),
      issue_date: p.membershipData?.issue_date,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState,
    clearErrors,
    control,
  } = createMembershipForm;

  const [step, setStep] = React.useState(0);
  const [formMembershipType] =
    React.useState<MembershipInfoTypesType>("cuci-unlimited");
  const [foundCustomer, setFoundCustomer] = React.useState<
    IReturnGetCustomers | undefined
  >(undefined);
  const [foundMembership, setFoundMembership] = React.useState<
    Exclude<IReturnGetCustomers["memberships"], undefined>[number] | undefined
  >(undefined);

  const carwashApiGetCustomerByPhoneNumberField = useWatchAndQuery({
    formHook: createMembershipForm,
    watchKey: "customer_phone_number",
    queryKey: ["customer-by-phone-number"],
    queryFn: async (watchCustomerPhoneNumberField) => {
      const resGetCustomer = await carwashApi_getCustomers({
        customer_phone_number: watchCustomerPhoneNumberField,
        select: ["cars", "memberships", "memberships.cars"],
      });
      const dataCustomer = resGetCustomer.data.data?.[0] || "";
      return dataCustomer;
    },
  });

  React.useEffect(() => {
    const foundCustomer = carwashApiGetCustomerByPhoneNumberField.data;
    if (foundCustomer) {
      setValue("customer_name", foundCustomer.customer_name);
    }
    const foundMembership =
      p.membershipData ||
      carwashApiGetCustomerByPhoneNumberField.data?.memberships?.find(
        (f) => f.member_type === formMembershipType
      );
    if (foundMembership) {
      // setValue("plate_number", foundMembership.cars?.[0].plate_number || "");
      setValue(
        "cars",
        foundMembership.cars?.map((c, index) => ({
          car_brand: c.car_brand || "",
          car_color: c.car_color || "",
          plate_number: c.plate_number || "",
        })) || [
          {
            car_brand: "",
            car_color: "",
            plate_number: "",
          },
        ]
      );
    }
    setFoundMembership(foundMembership);
    setFoundCustomer(foundCustomer);
  }, [
    carwashApiGetCustomerByPhoneNumberField.data,
    setFoundMembership,
    setFoundCustomer,
    p.membershipData,
  ]);

  // React.useEffect(() => {
  //   console.log(formState.errors);
  // }, [formState]);

  const secretFeaturesFlag = useSecretFeaturesFlag();

  const handleSubmitOnValid = useHandleSubmitOnValid();

  if (carwashApiGetCustomerByPhoneNumberField.isLoading && step !== 0)
    return <div>LOADING...</div>;

  return (
    <>
      <div style={{ display: step === 0 ? "initial" : "none" }}>
        <FormGroup as={Row} controlId="customer_phone_number">
          <FormLabel column>Customer Phone Number</FormLabel>
          <Col>
            <FormControl
              {...register("customer_phone_number", { required: true })}
              placeholder="Phone Number"
            />
          </Col>
          {formState.errors.customer_phone_number ? (
            <p style={{ color: "red" }}>
              {formState.errors.customer_phone_number.message}
            </p>
          ) : (
            ""
          )}
        </FormGroup>
        <br />
        <ArrayCarFields
          form={createMembershipForm}
          hide={["car_brand", "car_color"]}
        />
        <br /> <br />
        <Button onClick={() => setStep(1)}>Next</Button>
      </div>
      <Form
        style={{
          display: step === 1 ? "initial" : "none",
        }}
        onSubmit={(event) => {
          clearErrors();
          handleSubmit(async (data) => {
            console.log(data);
            // const extendExpireDate = data.duration
            //   ? window.confirm("Extend Expire Date? ")
            //   : false;
            const extendExpireDate = false;
            handleSubmitOnValid({
              data,
              foundCustomer: foundCustomer,
              foundMembership: foundMembership,
              extendExpireDate,
              setError,
              submissionMode: p.submissionMode,
            })
              .then(() => {
                if (checkIsToUpdate(p.submissionMode, foundMembership))
                  return p.onUpdateSucceed();
                p.onCreateSucceed();
              })
              .catch((e) => {
                // createMembershipForm.setError("customer_name", {
                //   message: "ERROR TEST",
                // });

                const errField = flattenErrorFieldReactHookForm(
                  createMembershipForm.formState.errors
                );

                const firstErrField = errField[0];

                alert(
                  firstErrField
                    ? firstErrField.key + ": " + firstErrField.message
                    : e?.response?.data?.message
                    ? isString(e.response.data.message)
                      ? e.response.data.message
                      : e.response.data.message.issues?.[0]?.path?.[0] +
                        ": " +
                        e.response.data.message.issues?.[0]?.message
                    : e
                );
              });
          })(event);
        }}
      >
        <FormGroup as={Row} controlId="customer_name">
          <FormLabel column>Customer Name</FormLabel>
          <Col>
            <FormControl
              {...register("customer_name")}
              placeholder="Customer Name"
            />
            {formState.errors.customer_name ? (
              <p style={{ color: "red" }}>
                {formState.errors.customer_name.message}
              </p>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>
        <br />
        <ArrayNomorKartusFields form={createMembershipForm} />
        <br />
        <br />
        <FormGroup as={Row} controlId="customer_phone_number">
          <FormLabel column>Customer Phone Number</FormLabel>
          <Col>
            <FormControl
              {...(step === 1 ? register("customer_phone_number") : {})}
              placeholder="Customer Phone Number"
              disabled
            />
            {formState.errors.customer_phone_number ? (
              <p style={{ color: "red" }}>
                {formState.errors.customer_phone_number.message}
              </p>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>
        <br />
        <If condition={step === 1}>
          <ArrayCarFields form={createMembershipForm} />
        </If>
        <br />
        <FormGroup as={Row} controlId="issue_date">
          <FormLabel column>Issue Date</FormLabel>
          <Col>
            <Controller
              control={control}
              name="issue_date"
              render={({ field }) => {
                return (
                  <ReactDatePicker
                    placeholderText="Issue Date"
                    dateFormat={"yyyy-MM-dd"}
                    minDate={secretFeaturesFlag ? null : new Date()}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    selected={field.value}
                  />
                );
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup as={Row} controlId="duration">
          <FormLabel column>Duration</FormLabel>
          <Col>
            <FormSelect {...register("duration")} defaultValue={0}>
              {/* <option value={0} disabled>
                Duration
              </option>
              <option value={1}>1 Month</option>
              <option value={3}>3 Months</option>
              <option value={6}>6 Months</option> */}
              <MembershipDurationOptions />
            </FormSelect>
          </Col>
        </FormGroup>
        <br />
        <FormGroup as={Row} controlId="price">
          <FormLabel column>Members Price</FormLabel>
          <Col>
            <FormControl
              {...(step === 1
                ? register("price", {
                    onChange: (e: any) => {
                      setValue(
                        "price",
                        thousandCommas(
                          e.currentTarget.value.replaceAll(",", "")
                        )
                      );
                    },
                  })
                : {})}
              placeholder="Price"
            />
            {formState.errors.price ? (
              <p style={{ color: "red" }}>{formState.errors.price.message}</p>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>
        <br />
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};
