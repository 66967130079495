import React from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import {
  CreateMemberShipFormType,
  MembershipForm,
  SubmissionMode,
} from "../components/membershipPages/MembershipForm.component";
import { useQuery } from "react-query";
import {
  IReturnGetMembeship,
  carwashApi_GetTotalAciveMemberships,
  carwashApi_GetTotalMembershipTransaction,
  carwashApi_deleteMembership,
  carwashApi_getMembership,
} from "../carwashApi";
import { DateTime } from "luxon";
import { TablePagination } from "../components/TableComponent/TablePagination.component";
import {
  IMembershipFilterForm,
  MembershipFilter,
} from "../components/membershipPages/MembershipFilter.component";
import { thousandCommas } from "../utilities";
import { MembershipUsedButton } from "../components/membershipPages/MembershipActionButtons/MembershipUsedButton.component";
import { MembershipActivateButton } from "../components/membershipPages/MembershipActionButtons/MembershipActivateButton.component";
import { MembershipExtendButton } from "../components/membershipPages/MembershipActionButtons/MembershipExtendButton.component";
import { MembershipChangeTypeButton } from "../components/membershipPages/MembershipActionButtons/MembershipChangeTypeButton.component";
import { useSecretFeaturesFlag } from "../hooks/useSecretFeatures";

export const MembershipPage = () => {
  const [show, setShow] = React.useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [rowPerPage] = React.useState(10);
  const [filterData, setFilterData] = React.useState<IMembershipFilterForm>();
  const membershipQuery = useQuery(
    ["memberships", filterData, activePage, rowPerPage],
    async (x) => {
      const res = await carwashApi_getMembership({
        select: ["customer", "cars", "membership_info_kartus"],
        sort: "customer.customer_name",
        "cars.plate_number_like": filterData?.plate_number || undefined,
        "customer.customer_phone_number_like":
          filterData?.customer_phone_number || undefined,
        "membership_info_kartus.nomor_kartu_like":
          filterData?.nomor_kartu || undefined,
        limit: rowPerPage,
        skip: rowPerPage * (activePage - 1),
      });
      return res.data;
    },
    { retry: false }
  );

  const totalActiveMembershipQuery = useQuery(
    ["totalActiveMembership", membershipQuery.data],
    async () => {
      const res = await carwashApi_GetTotalAciveMemberships();
      return res.data;
    },
    { cacheTime: 60 }
  );

  const totalMemberUsageOneMonthQuery = useQuery(
    ["totalMemberUsageOneMonth", membershipQuery.data],
    async () => {
      const luxonNow = DateTime.now();
      const transaction_date_max = luxonNow.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      const transaction_date_min = luxonNow
        .minus({ month: 1 })
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      // console.log(
      //   transaction_date_max.toFormat("yyyy-MM-dd HH:mm:ss"),
      //   transaction_date_min.toFormat("yyyy-MM-dd HH:mm:ss"),
      //   transaction_date_max.diff(transaction_date_min, "days").days
      // );
      const daysDiff = transaction_date_max.diff(
        transaction_date_min,
        "days"
      ).days;

      const res = await carwashApi_GetTotalMembershipTransaction({
        transaction_date_max: transaction_date_max.toJSDate(),
        transaction_date_min: transaction_date_min.toJSDate(),
      });
      return {
        ...res.data,
        daysDiff,
        transaction_date_min,
        transaction_date_max,
      };
    }
  );
  // console.log(membershipQuery.data?.data);
  const [membershipdata, setMembershipData] = React.useState<
    IReturnGetMembeship | undefined
  >(undefined);

  const [submissionMode, setSubmissionMode] = React.useState<
    SubmissionMode | undefined
  >(undefined);

  const secretFeaturesFlag = useSecretFeaturesFlag();
  return (
    <Container fluid style={{ paddingTop: "10px" }}>
      <MembershipFilter
        onFilter={(data) => {
          setFilterData({ ...data });
        }}
      />
      <Row>
        <Col>
          <h3>
            Active Member: {totalActiveMembershipQuery.data?.totalActiveMembers}
          </h3>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <h3>
            Total Member Usage:{" "}
            {totalMemberUsageOneMonthQuery.data?.totalTransaction
              ? totalMemberUsageOneMonthQuery.data?.totalTransaction
              : ""}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            {" "}
            {`(${totalMemberUsageOneMonthQuery.data?.transaction_date_min.toFormat(
              "yyyy-MM-dd"
            )} - ${totalMemberUsageOneMonthQuery.data?.transaction_date_max.toFormat(
              "yyyy-MM-dd"
            )})`}
          </h4>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <h3>
            Avg Member Usage:{" "}
            {totalMemberUsageOneMonthQuery.data?.totalTransaction
              ? (
                  totalMemberUsageOneMonthQuery.data?.totalTransaction /
                  totalMemberUsageOneMonthQuery.data.daysDiff
                ).toFixed(2)
              : ""}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            {" "}
            {`(${totalMemberUsageOneMonthQuery.data?.transaction_date_min.toFormat(
              "yyyy-MM-dd"
            )} - ${totalMemberUsageOneMonthQuery.data?.transaction_date_max.toFormat(
              "yyyy-MM-dd"
            )})`}
          </h4>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <h3>Membership</h3>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setShow(true);
              setSubmissionMode("create");
              setMembershipData(undefined);
            }}
          >
            Add New Member
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: "10px" }}>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Customer Phone Number</th>
              <th>Cars</th>
              <th>Member Type</th>
              <th>Member Status</th>
              <th>Nomor Kartu</th>
              <th>Duration</th>
              <th>Price</th>
              <th>Expire Date</th>
              <th>Issue Date</th>
              <th>Last Usage Date</th>
            </tr>
          </thead>
          <tbody>
            {membershipQuery.data?.data.map((membership) => (
              <tr
                key={membership.membership_id}
                className={[
                  new Date(membership.issue_date) >
                  DateTime.now()
                    .set({ hour: 23, minute: 59, second: 59 })
                    .toJSDate()
                    ? "membernotactiveyet"
                    : "",
                  new Date(membership.last_usage_date) >
                  DateTime.now()
                    .set({ hour: 0, minute: 0, second: 0 })
                    .toJSDate()
                    ? "memberused"
                    : "",
                  new Date(membership.expire_date) <
                  DateTime.now()
                    .set({ hour: 0, minute: 0, second: 0 })
                    .toJSDate()
                    ? "memberexpired"
                    : "",
                ].join(" ")}
              >
                <td>{membership.customer?.customer_name || ""}</td>
                <td>{membership.customer?.customer_phone_number || ""}</td>
                <td>
                  {membership.cars && membership.cars.length > 0 ? (
                    membership.cars.map((car) => (
                      <Table bordered key={car.plate_number}>
                        <tbody>
                          <tr>
                            <td>Plate Number</td>
                            <td>{car.plate_number}</td>
                          </tr>
                          <tr>
                            <td>Car Brand</td>
                            <td>{car.car_brand}</td>
                          </tr>
                          <tr>
                            <td>Car Color</td>
                            <td>{car.car_color}</td>
                          </tr>
                        </tbody>
                      </Table>
                    ))
                  ) : (
                    <tr>
                      <td></td>
                    </tr>
                  )}
                </td>
                <td>{membership.member_type}</td>
                <td>{membership.status}</td>
                <td>
                  {(() => {
                    return membership.membership_info_kartus &&
                      membership.membership_info_kartus.length ? (
                      membership.membership_info_kartus.map((nomor) => {
                        return (
                          <Table bordered key={nomor.nomor_kartu}>
                            <tr>
                              <td>Nomor Kartu</td>
                              <td>{nomor.nomor_kartu}</td>
                            </tr>
                          </Table>
                        );
                      })
                    ) : (
                      <tr>
                        <td></td>
                      </tr>
                    );
                  })()}
                </td>
                <td>{membership.duration}</td>
                <td>
                  {"Rp. " + thousandCommas((membership.price || 0).toString())}
                </td>
                <td>
                  {DateTime.fromJSDate(
                    new Date(membership.expire_date)
                  ).toFormat("yyyy-MM-dd")}
                </td>
                <td>
                  {DateTime.fromJSDate(
                    new Date(membership.issue_date)
                  ).toFormat("yyyy-MM-dd")}
                </td>
                <td>
                  {DateTime.fromJSDate(
                    new Date(membership.last_usage_date)
                  ).toFormat("yyyy-MM-dd HH:mm:ss")}
                </td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <Button
                            size="sm"
                            onClick={() => {
                              setShow(true);
                              setSubmissionMode("update");
                              setMembershipData(membership);
                            }}
                          >
                            Edit
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ padding: "5px" }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Button
                            size="sm"
                            onClick={() => {
                              const confirmation =
                                window.confirm("Confirm to delete");
                              if (confirmation) {
                                carwashApi_deleteMembership(
                                  membership.membership_id
                                ).then((r) => {
                                  membershipQuery.refetch();
                                });
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ padding: "5px" }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {membership.cars ? (
                            <MembershipUsedButton
                              cars={membership.cars}
                              membership_id={membership.membership_id}
                              onUseSuccess={(data) => {
                                membershipQuery.refetch();
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ padding: "5px" }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <MembershipExtendButton
                            membership={membership}
                            onExtendSuccess={(data) => {
                              membershipQuery.refetch();
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ padding: "5px" }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <MembershipActivateButton
                            membership_id={membership.membership_id}
                            onActivationSuccess={() => {
                              alert("Activated");
                              membershipQuery.refetch();
                            }}
                          />
                        </td>
                      </tr>
                      {secretFeaturesFlag ? (
                        <tr>
                          <td>
                            <MembershipChangeTypeButton
                              membership_id={membership.membership_id}
                              onChangeSuccess={() => {
                                alert("change succeed");
                                membershipQuery.refetch();
                              }}
                            />
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <TablePagination
          total_page={Math.ceil(
            (membershipQuery.data?.count || 0) / rowPerPage
          )}
          // total_page={10}
          active_page={activePage}
          onClick={(page) => {
            setActivePage(page);
          }}
        />
      </Row>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setSubmissionMode(undefined);
          setMembershipData(undefined);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Membership Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MembershipForm
            onCreateSucceed={() => {
              alert("Create Member Succeed");
              membershipQuery.refetch();
              setShow(false);
            }}
            onUpdateSucceed={() => {
              alert("Edit Member Succeed");
              membershipQuery.refetch();
              setShow(false);
            }}
            membershipData={membershipdata}
            submissionMode={submissionMode}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};
