import React from "react";

const secretContext = React.createContext(false);

export const SecretFeatureProvider = (p: { children: React.ReactNode }) => {
  const [flag, setFlag] = React.useState(false);

  const onkeyup = React.useCallback(
    (ev: KeyboardEvent) => {
      console.log(ev.ctrlKey, ev.altKey, ev.code, flag);
      if (ev.ctrlKey && ev.altKey && ev.code === "KeyL") {
        setFlag((prevflag) => !prevflag);
      }
    },
    [flag]
  );
  React.useEffect(() => {
    window.addEventListener("keydown", onkeyup);
    return () => window.removeEventListener("keydown", onkeyup);
  }, []);

  React.useEffect(() => {
    console.log(flag);
  }, [flag]);
  return (
    <secretContext.Provider value={flag}>{p.children}</secretContext.Provider>
  );
};

export const useSecretFeaturesFlag = () => {
  const ctx = React.useContext(secretContext);
  return ctx;
};
