import React from "react";
import { Button } from "react-bootstrap";
import { carwashApi_MembershipChangeType } from "../../../carwashApi";
import { isString } from "lodash";

export const MembershipChangeTypeButton = (p: {
  membership_id: string;
  onChangeSuccess: () => void;
}) => {
  return (
    <Button
      size="sm"
      onClick={() => {
        const secretInput = prompt("Secret");
        if (!secretInput) return alert("SECRET EMPTY");
        carwashApi_MembershipChangeType({
          membership_id: p.membership_id,
          member_type: "owner",
          secret: secretInput,
        })
          .then(() => {
            p.onChangeSuccess();
          })
          .catch((e) => {
            alert(
              e?.response?.data?.message
                ? isString(e.response.data.message)
                  ? e.response.data.message
                  : e.response.data.message.issues?.[0]?.path?.[0] +
                    ": " +
                    e.response.data.message.issues?.[0]?.message
                : e
            );
          });
      }}
    >
      Owner
    </Button>
  );
};
